var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.FF_Devices
    ? _c("div", { staticClass: "gmp-devices-page__overview" }, [
        _c("div", [
          _c(
            "div",
            { class: { "is-mobile-or-tablet-view": !_vm.isDesktopView } },
            [
              _c(
                "h2",
                { staticClass: "my-account__title gds-space-stack-default" },
                [_vm._v("\n        Devices Overview\n      ")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-space-stack-s" },
            [
              _c("router-link", { attrs: { to: "/devices/add-device" } }, [
                _vm._v("\n        + Add a new device\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.isDesktopView
            ? _c(
                "div",
                {
                  staticClass: "gds-space-stack-s",
                  class: { "is-desktop-content": _vm.isDesktopView }
                },
                [_vm._v("\n      DESKTOP CONTENT\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isDesktopView
            ? _c(
                "div",
                {
                  staticClass: "gds-space-stack-s",
                  class: { "is-mobile-or-tablet-content": !_vm.isDesktopView }
                },
                [_vm._v("\n      TABLET / MOBILE CONTENT\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "gds-space-stack-s" }, [
            _vm._v("\n      Devices List\n    ")
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("Device # 123 - "),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToDetailRoute(123)
                    }
                  }
                },
                [_vm._v("View Detail")]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Device # 456 - "),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToDetailRoute(456)
                    }
                  }
                },
                [_vm._v("View Detail")]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Device # 789 - "),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToDetailRoute(789)
                    }
                  }
                },
                [_vm._v("View Detail")]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }