<template>
  <div
    v-if="FF_Devices"
    class="gmp-devices-page__overview">
    <div>
      <div
        :class="{ 'is-mobile-or-tablet-view': !isDesktopView}"
      >
        <h2 class="my-account__title gds-space-stack-default">
          Devices Overview
        </h2>
      </div>
      <div class="gds-space-stack-s">
        <router-link to="/devices/add-device">
          + Add a new device
        </router-link>
      </div>
      <div
        v-if="isDesktopView"
        class="gds-space-stack-s"
        :class="{ 'is-desktop-content': isDesktopView}"
      >
        DESKTOP CONTENT
      </div>
      <div
        v-if="!isDesktopView"
        class="gds-space-stack-s"
        :class="{ 'is-mobile-or-tablet-content': !isDesktopView}"
      >
        TABLET / MOBILE CONTENT
      </div>
      <div class="gds-space-stack-s">
        Devices List
      </div>
      <ul>
        <li>Device # 123 - <span @click="goToDetailRoute(123)">View Detail</span></li>
        <li>Device # 456 - <span @click="goToDetailRoute(456)">View Detail</span></li>
        <li>Device # 789 - <span @click="goToDetailRoute(789)">View Detail</span> </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../mixins/MediaQueryMixin';
import { isFeatureEnabled } from '../../../../services/featureflags';

export default {
  name: 'DevicesOverview',
  mixins: [MediaQueryMixin],
  data() {
    return {
      FF_Devices: undefined,
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  async mounted() {
    await this.checkFeatureFlag();
  },
  methods: {
    goToDetailRoute(id) {
      this.$router.push({ path: `/devices/${id}` });
    },
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
