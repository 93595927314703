<template>
  <PageTransitLogin :isChildComponent="true"/>
</template>

<script>
import PageTransitLogin from "../transitlogin/PageTransitLogin.vue";

export default {
  name: "EICProductsLogin",
  components: {
    PageTransitLogin
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>